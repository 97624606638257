import React from "react";
import { useQuery, gql } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";

import styles from "./changeLog.module.scss";

const QUERY = gql`
  query {
    changeLogCollection {
      items {
        date
        content {
          json
        }
      }
    }
  }
`;

const ChangeLog = () => {
  const { data, error, loading } = useQuery(QUERY);

  if (loading)
    return (
      <Box className={styles.loading}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box className={styles.error}>
        <Alert severity="error" sx={{ width: "100%" }}>
          Error: {error.message}
        </Alert>
      </Box>
    );

  return (
    <div className={styles.changeLog}>
      {data.changeLogCollection.items
        .slice(0, 15)
        .map((section: { date: string; content: { json: any } }) => (
          <Accordion key={section.date}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h4>{section.date.split("T")[0]}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {documentToReactComponents(section.content.json)}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default ChangeLog;
