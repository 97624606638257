import React, { useRef } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import styles from "./home.module.scss";
import Heading from "../components/Heading";
import Section from "../components/Section";
import relationDiagram from "../images/model_diagram_2.png";
import modelDiagram from "../images/model_diagram.png";
import getAccessKey from "../images/get_access_key.gif";
import insomniaPeople from "../images/list_all_people_insomnia.png";
import insomniaAuthorization from "../images/header_example_with_authorization_and_tenant_insomnia.png";
import ChangeLog from "../components/ChangeLog";
import NavButton from "../components/NavButton";

const alertContent = (
  <>
    <h3>
      The latest public API Docs can be found{" "}
      <a href={`${window.location.href}docs/v1/`}>here</a>
    </h3>
    <p>
      <strong>Version 1 of the intelliHR public API is now released!</strong>{" "}
      This version can be considered stable and production ready for
      integrations with third party systems.
    </p>
    <p>
      Note that future endpoints and data models may be added to this API in the
      near future, but no breaking changes will be made to any endpoints on v1.
    </p>
    <p>
      If you have any suggestions, feedback or complaints, please contact us at
      support@intellihr.co, and mention that you have an enquiry about our
      public API and we can put you in touch directly with the development team.
    </p>
  </>
);

const introductionContent = (
  <div>
    <p>
      Welcome to the intelliHR API! Our public API will give you access to
      multiple endpoints, such as the People API (to lookup, list or create a
      person), or the Jobs API (to retrieve or insert Job related information).
    </p>
    <p>
      The API is organised around REST. All requests should be made over HTTPS.
      All request response bodies, including errors are encoded in JSON.
    </p>
    <p>
      To learn more about the API endpoints, proceed to our{" "}
      <a href={`${window.location.href}docs/v1/`}>API docs</a> page.
    </p>
    <p>
      API status and uptime statistics can be found{" "}
      <a
        href="http://stats.pingdom.com/fwqaxhft3dij/5148338"
        target="_blank"
        rel="noreferrer"
      >
        here
      </a>
      .
    </p>
    <p>
      The intelliHR API aims to help companies, both customers and partners, 
      to build custom HRIS solutions that can grow and scale within their own industries, 
      existing workflows and processes.
    </p>
    <p>
      If you are interested in joining our Partnership program or ready to build an integration 
      to intelliHR <a href="https://survey-intellihr.typeform.com/to/GhYQ4wBM">please fill out this 
      form to register your intent</a>.
    </p>
    <p>
      Once your registration has been accepted we will book a pre-deployment session with our integration team to support you throughout the process.
    </p>
  </div>
);

const rateLimitContent = (
  <div>
    <p>
      The intelliHR public API limits requests based on the tenant over a period of time. This helps to maintain API integrity and response times for all customers.
    </p>
    <p>
      The public API will always respond with these headers to help manage your API request rate:
    </p>
    <ul>
      <li><strong>Retry-After</strong>: Seconds until the limit is reset</li>
      <li><strong>X-RateLimit-Limit</strong>: The current request limit.</li>
      <li><strong>X-RateLimit-Remaining</strong>: Amount of requests remaining before the limit is reached</li>
      <li><strong>X-RateLimit-Reset</strong>: The Exact DateTime the limit will reset.</li>
    </ul>
    <p>
      When the limit has been reached the response will return a <strong>HTTP status code of 429</strong> and no data.
    </p>
    <Divider />
    <p>
      The current limit is <strong>250 requests per 60 seconds</strong>.
    </p>
    <p>
      If you would like this limit to be increased, please talk to your Account Manager.
    </p>
  </div>
);

const overviewContent = (
  <div className={styles.overview}>
    <p>
      Here we will provide find a quick overview of relations between tenants,
      people, users and jobs.
    </p>
    <br />
    <p>
      <b>Tenant</b>
    </p>
    <p>Your company/organisation</p>

    <p>
      <b>Person</b>
    </p>
    <p>
      A person/staff member within your company/organisation.
      <br />
      People represent the base objects in the intelliHR data model. All past,
      present and future employees should be created as a Person and then have a
      Job created which references that Person object.
    </p>

    <p>
      <b>User Account</b>
    </p>
    <p>
      User accounts give employees access to the application and is linked to an
      existing Person.
      <br />A Person can exist without a User, as employees can still complete
      tasks sent to their primary email address.
    </p>
    <p>
      Currently, User creation is not supported through the intelliHR API, and
      must be created within the dashboard.
    </p>

    <p>
      <b>Job</b>
    </p>
    <p>
      The Job object is the core data object for past, current and future roles
      that a Person holds in the organisation.
      <br />
      Jobs are effective dated, a Person can have more than one Job at a point
      in time to represent secondment / role sharing.
    </p>

    <br />
    <p>
      <i>Relation Diagram</i>
    </p>
    <img src={relationDiagram} alt="Relation Diagram" />
    <br />
    <br />
    <p>
      <i>Model Diagram</i>
    </p>
    <img src={modelDiagram} alt="Model Diagram" />
  </div>
);

const authenticationContent = (
  <div className={styles.authentication}>
    <p>
      intelliHR is using a string based authentication token for API request
      authorisation. In order to use the intelliHR API, you will have to send a
      token generated for your organisation with every request. Here is how you
      can obtain one for your system:
    </p>
    <Card>
      <CardMedia
        component="img"
        image={getAccessKey}
        alt="Get API Access Key"
      />
      <CardContent>
        <ol>
          <li>
            Use your intelliHR credentials to login to the intellihr platform.
          </li>
          <li>Using the main menu on your left, go to: Settings.</li>
          <li>Under the settings menu, go to: Public Api Access Keys.</li>
          <li>Click Create Access Key button.</li>
          <li>When prompted, enter a name for the Access Key.</li>
          <li>
            Copy the generated Access Key somewhere safe. (Keep in mind that you
            won't be able to regenerate the same Access Key again, so make sure
            you save it.)
          </li>
        </ol>
      </CardContent>
    </Card>
    <p>
      You can now send API requests to intelliHR using the generated Access Key.
      Note that currently there is no support for permission scopes - any API
      token can access all endpoints and should be considered the same as full
      system administrator access. Please email us at support@intellihr.co to
      tell us about your use case for permission scoped tokens!
    </p>
  </div>
);

const usageContent = (
  <div className={styles.usage}>
    <p>
      To quickly test your access tokens and get familiar with the API, we
      recommend using <a href="https://insomnia.rest/">insomnia</a> to try and
      send some test API requests. Take a look at an example below.
    </p>
    <ol>
      <li>
        Download our OpenAPI Specification <code>swagger.json</code> by visiting{" "}
        <a href={`${window.location.href}docs/v1/`}>here.</a>
      </li>
      <li>
        Import <code>swagger.json</code> file to Insomnia.
        <ul>
          <li>
            Select "Import/Export" by clicking "Insomnia" icon on top left.
          </li>
          <li>
            {'In "Data" tab, select "Import Data" => "From File" and select'}
            <code>swagger.json</code> file.
          </li>
          <li>Select "New Workspace" in the popup window.</li>
        </ul>
      </li>
      <li>
        Configure the environment.
        <ul>
          <li>
            In the newly created workspace, click "No Environment" tab to select
            "Manage Environments".
          </li>
          <li>
            In "OpenAPI env", replace the prepopulated value for "apiKey" with
            your own API access key with "bearer" as a prefix.
          </li>
          <li>
            In "OpenAPI env", add "tenant" key value pair. "tenant" is your
            organisation tenant name which will be the subdomain part of your
            application URL for example:{" "}
            <code>https://&lt;tenant&gt;.intellihr.net</code>
          </li>
          You will have the following environment variables in the end.
        </ul>
        <pre>
          <code>
            {`            {
              "tenant": "&lt;your-organisation-tenant-name&gt;",
              "apiKey": "bearer &lt;your-own-secret-api-key&gt;",
              "base_path": "/v1",
              "host": "api.intellihr.io",
              "scheme": "https"
            }`}
          </code>
        </pre>
      </li>
      <li>
        Click "No Environment" tab and select "Use OpenAPI env" to apply the
        configured environment setting.
      </li>
      <li>
        Make a request.
        <ul>
          <li>
            Go to "People" section on the left side bar and select{" "}
            <code>List all people</code> request.
            <img src={insomniaPeople} alt="List all people in Insomnia" />
          </li>
          <li>
            In the "Header" tab, put the <code>tenant</code> value by selecting
            from the environment variable list (and notice{" "}
            <code>Authorization</code> is already populated).
            <img src={insomniaAuthorization} alt="Header example in Insomnia" />
          </li>
        </ul>
      </li>
    </ol>
    <p>
      After you send this request you should be getting a response that follows
      the following format:
    </p>
    <Divider />
    <pre>
      <code>
        {`{
  "meta": {
    "pagination": {
      "count": 10,
      "current_page": 1,
      "per_page": 10,
      "total": 306,
      "total_pages": 31
    }
  },
  "links": {
    "self": "http://api.intellihr.io/v1/people?limit=10&page=1",
    "first": "http://api.intellihr.io/v1/people?limit=10&page=1",
    "next": "http://api.intellihr.io/v1/people?limit=10&page=2",
    "last": "http://api.intellihr.io/v1/people?limit=10&page=31"
  },
  "data": [
    {
      "id": "00000000-0000-0000-0000-000000000000",
      "displayName": "Bruce (Batman) Wayne",
      "firstName": "Bruce",
      "middleName": "Thomas",
      "lastName": "Wayne",
      "preferredName": "Batman",
      "dateOfBirth": "1939-07-23",
      "gender": "Male",
      "employeeNumber": "00000",
      "autoIncrementingIntellihrId": 1234,
      "title": "Mr",
      "emergencyContact": {
        "name": "Selina Kyle",
        "relationship": "Colleague",
        "phone": "00 0000 0000",
        "email": "selina.kyle@catwoman.org"
      },
      "employmentStatus": "Current Staff",
      "jobs": [
        {
        "id": "00000000-0000-0000-0000-000000000000",
        "name": "Software Engineer",
        "startDate": "2003-02-11T14:00:00+00:00",
        "endDate": "2017-02-11T14:00:00+00:00",
        "jobStatus": "Current Job",
        "link": "http://api.intellihr.io/v1/jobs/00000000-0000-0000-0000-000000000000"
        }
      ],
      "primaryEmailAddress": "dark.knight@batman.org",
      "createdAt": "2019-08-28T04:32:20+00:00",
      "updatedAt": "2019-08-28T04:32:20+00:00"
    },
    ... 9 more records of "type": "people" ...
  ]
}`}
      </code>
    </pre>
    <Divider />
    <p>
      Note how every request will return <code>meta</code> and{" "}
      <code>links</code> attributes with pagination specific information.
    </p>
    <p>
      The <code>links</code> section will provide you with pagination links,
      however <code>prev</code> and <code>next</code> pages may not always be
      displayed. For instance, in the example above <code>prev</code> page is
      not provided, because the current page displayed is the first one.
      Similarly, when you hit the last page, the API will not provide you with a
      link to the <code>next</code> page.
    </p>
    <p>
      To see the full API documentation please go to our{" "}
      <a href={`${window.location.href}docs/v1/`}>API docs</a> page.
    </p>
  </div>
);

const examplesContent = (
  <div className={styles.examples}>
    <p>Below are examples of programmatic API calls.</p>
    <ul>
      <li>
        <h4>JavaScript</h4>
        <Divider />
        <pre>
          <code>{`
fetch('http://api.intellihr.io/v1/people', {
  method: 'GET',
  headers: {
    Authorization: 'Bearer your-secret-access-token',
    Tenant: 'your-organisation-tenant-name',
  },
})
  .then(
    function(response) {
      jsonFormatterResponse = response.json();

      console.log(jsonFormatterResponse);
    }
  );
    `}</code>
        </pre>
        <Divider />
      </li>
      <li>
        <h4>Python</h4>
        <Divider />
        <pre>
          <code>{`
import urllib.request

url = 'http://api.intellihr.io/v1/people'
headers = {
  'Authorization': 'Bearer your-secret-access-token',
  'Tenant': 'your-organisation-tenant-name'
}
req = urllib.request.Request(url, None, headers)

with urllib.request.urlopen(req) as response:
  list_of_people_result = response.read()
  print(list_of_people_result)
    `}</code>
        </pre>
      </li>
      <Divider />
      <li>
        <h4>Curl</h4>
        <Divider />
        <pre>
          <code>{`
curl \\
  -H "Authorization: Bearer your-secret-access-token" \\
  -H "Tenant: your-organisation-tenant-name" \\
  https://api.intellihr.io/v1/people
    `}</code>
        </pre>
        <Divider />
      </li>
    </ul>
    <p>
      To see the full API documentation please go to our{" "}
      <a href={`${window.location.href}docs/v1/`}>API docs</a> page.
    </p>
  </div>
);

const Home = () => {
  const introductionRef = useRef<HTMLElement>(null!);
  const overviewRef = useRef<HTMLElement>(null!);
  const rateLimitRef = useRef<HTMLElement>(null!);
  const authRef = useRef<HTMLElement>(null!);
  const usageRef = useRef<HTMLElement>(null!);
  const examplesRef = useRef<HTMLElement>(null!);
  const changeRef = useRef<HTMLElement>(null!);

  console.log(window.location);

  return (
    <Container className={styles.container} maxWidth="lg">
      <Heading />
      <Paper className={styles.alert}>{alertContent}</Paper>
      <div className={styles.contentBox}>
        <Paper className={styles.navigation}>
          <NavButton name="Introduction" elRef={introductionRef} />
          <Divider />
          <NavButton name="Overview" elRef={overviewRef} />
          <Divider />
          <NavButton name="Authentication" elRef={authRef} />
          <Divider />
          <NavButton name="Rate Limits" elRef={rateLimitRef} />
          <Divider />
          <NavButton name="Usage" elRef={usageRef} />
          <Divider />
          <NavButton name="Examples" elRef={examplesRef} />
          <Divider />
          <NavButton name="Change Log" elRef={changeRef} />
        </Paper>
        <div className={styles.content}>
          <Section title="Introduction" elRef={introductionRef}>
            {introductionContent}
          </Section>
          <Section title="Overview" elRef={overviewRef}>
            {overviewContent}
          </Section>
          <Section title="Authentication" elRef={authRef}>
            {authenticationContent}
          </Section>
          <Section title="Rate Limits" elRef={rateLimitRef}>
            {rateLimitContent}
          </Section>
          <Section title="Usage" elRef={usageRef}>
            {usageContent}
          </Section>
          <Section title="Examples" elRef={examplesRef}>
            {examplesContent}
          </Section>
          <Section title="Change Log" elRef={changeRef}>
            <ChangeLog />
          </Section>
        </div>
      </div>
    </Container>
  );
};

export default Home;
