import React from "react";

import styles from "./heading.module.scss";

const Heading = () => {
  return (
    <div className={styles.container}>
      <img src="https://assets.intellihr.io/static/images/logos/IntelliHR-on-White-RGB.png" alt="IntelliHR logo" />
      <h1>Getting Started with the intelliHR API</h1>
      <p>
        Welcome! This guide will show you how to get started with the intelliHR
        public API.
      </p>
    </div>
  );
};

export default Heading;
