import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import styles from './navButton.module.scss';

interface NavButtonProps {
  name: string;
  elRef: any;
}

const NavButton = ({ name, elRef }: NavButtonProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    elRef.current.scrollIntoView();
  };

  useEffect(() => {
    const handleScroll = () => {
      const isCurrentActive =
        (window.scrollY >= elRef.current.offsetTop - 1 && //section in view
          !( //not scrolled past
            elRef.current.scrollHeight + elRef.current.offsetTop <
            window.scrollY
          ) &&
          !( //not at bottom of page
            window.scrollY ===
            document.body.clientHeight - window.innerHeight
          )) || // or at bottom of page and the last section
        (window.scrollY === document.body.clientHeight - window.innerHeight && (
          elRef.current.offsetTop  > window.scrollY || (window.innerHeight < elRef.current.scrollHeight &&
            window.scrollY - elRef.current.offsetTop < 1000)))
      if (isCurrentActive !== isActive) setIsActive(isCurrentActive);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [elRef, isActive]);

  return (
    <Button
      className={`${styles.navButton} ${isActive? styles.active : ''}`}
      onClick={handleClick}
      variant="text"
      color="inherit"
      fullWidth
    >
      {name}
    </Button>
  );
};

export default NavButton;
